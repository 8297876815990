<section class="filter-container">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'TEAMS_TABLE_RANKING.FILTER.LABEL' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" [placeholder]="'TEAMS_TABLE_RANKING.FILTER.PLACEHOLDER' | translate " #input>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</section>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Position by points Column -->
    <ng-container matColumnDef="positionByPoints">
      <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS_TABLE_RANKING.RANK' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.positionByPoints }}
      </td>
    </ng-container>

    <!-- Position by average Column -->
    <ng-container matColumnDef="positionByAverage">
      <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS_TABLE_RANKING.RANK' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.positionByAverage }}
      </td>
    </ng-container>

    <!-- Avatar Column -->
    <ng-container matColumnDef="teamAvatar">
      <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS_TABLE_RANKING.AVATAR' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        <img class="team-avatar" #teamAvatar [src]="teamRanking.teamAvatar" [alt]="'Team avatar ' + teamRanking.teamName" (error)="setDefaultImagePath(teamAvatar)">
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="teamName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.NAME' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.teamName }}
      </td>
    </ng-container>

    <!-- Number of Members Column -->
    <ng-container matColumnDef="teamSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.MEMBERS' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking, let i = index">
        {{ teamRanking.teamSize }}
      </td>
    </ng-container>

    <!-- Performance column -->
    <ng-container matColumnDef="totalPerformance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.TOTAL_STEPS' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.totalPerformance }}
      </td>
    </ng-container>

    <!-- Syncs Column -->
    <ng-container matColumnDef="totalSyncs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.TOTAL_SYNCS' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.totalSyncs }}
      </td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="teamLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.LOCATION' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.teamLocation }}
      </td>
    </ng-container>

    <!-- Points Column -->
    <ng-container matColumnDef="points">
      <th class="header-align-right" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.POINTS' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.points }}
      </td>
    </ng-container>

    <!-- Average Performance Column -->
    <ng-container matColumnDef="average">
      <th class="header-align-right" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE_RANKING.AVERAGE_PERFORMANCE' | translate }} </th>
      <td mat-cell *matCellDef="let teamRanking">
        {{ teamRanking.average | number: '1.2-2':'de' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{ 'TEAMS_TABLE_RANKING.FILTER.MESSAGE' | translate}} "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator pageSize="10" aria-label="Select ranking page"></mat-paginator>
</div>
