export interface ITeamMember {
  avatar: string;
  email: string;
  id: string;
  nickname: string;
}

export class TeamMember {
  public avatar: string = '';
  public email: string = '';
  public id: string = '';
  public nickname: string = '';

  constructor(initializer?: ITeamMember) {
    Object.assign(this, initializer);
  }

  public toObject(): ITeamMember {
    return Object.assign({}, this);
  }
}
