<div class="container">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab [label]="'TEAM_CHALLENGE_V2.CURRENT_CHALLENGE' | translate">
      <!-- TODO: Only for dev purposes, later we will move it to the correct place -->
      <app-team-challenge-overview></app-team-challenge-overview>
      <app-team-challenge-dashboard *ngIf="!loading && (currentTeamChallengeDashboard || nextTeamChallenge)" [teamChallengeDashboard]="currentTeamChallengeDashboard"></app-team-challenge-dashboard>
      <app-no-current-challenge *ngIf="!loading && !(currentTeamChallengeDashboard || nextTeamChallenge)" [noCurrentChallenge]="noTeamChallenge"></app-no-current-challenge>
      <div class="spinner-container" *ngIf="loading && !(currentTeamChallengeDashboard || nextTeamChallenge)">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab [label]="'TEAM_CHALLENGE_V2.COMPLETED_CHALLENGES' | translate">
      <!-- <div class="form-field-container">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'CITIES_CHALLENGE.SELECT_CHALLENGE' | translate }}</mat-label>
          <mat-select [formControl]="selectedChallengeFormControl">
            <mat-option *ngFor="let citiesChallenge of completedCitiesChallenges" [value]="citiesChallenge">
              {{ citiesChallenge.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ 'CITIES_CHALLENGE.HINT' | translate }}</mat-hint>
        </mat-form-field>
      </div>
      <app-cities-challenge-dashboard *ngIf="completedCitiesChallengeDashboard" [citiesChallengeDashboard]="completedCitiesChallengeDashboard"></app-cities-challenge-dashboard>
    -->
      <div class="spinner-container" *ngIf="loading && !completedTeamChallengeDashboard">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
