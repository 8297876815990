import { ITeamRanking, TeamRanking } from '../team-ranking/team-ranking';

export enum SortProps {
  points = 'points',
  performance = 'performance',
  totalAveragePerformance = 'totalAveragePerformance',
}

export interface IWeeklyHistory {
  teams: { [teamId: string]: ITeamRanking };
  weekdays: Array<string>;
}

export class WeeklyHistory {
  public teams: Map<string, TeamRanking> = new Map();
  public weekdays: Array<string> = [];

  constructor(initializer?: Partial<IWeeklyHistory>) {
    if (initializer) {
      Object.assign(this, initializer);

      this.teams = this.convertITeamRankingObjectToTeamRankingObject(
        initializer.teams
      );
    }
  }

  public toObject(): IWeeklyHistory {
    const { teams, weekdays } = this;

    const objectToReturn = {
      weekdays,
      teams: this.convertTeamRankingObjectToITeamRankingObject(teams),
    };

    return objectToReturn;
  }

  private convertITeamRankingObjectToTeamRankingObject(teamRankingObject: {
    [teamId: string]: ITeamRanking;
  }): Map<string, TeamRanking> {
    return new Map(
      Object.entries(teamRankingObject).map(([teamId, iTeamRanking]) => [
        teamId,
        new TeamRanking(iTeamRanking),
      ])
    );
  }

  private convertTeamRankingObjectToITeamRankingObject(
    teamRankingMap: Map<string, TeamRanking>
  ): { [teamId: string]: ITeamRanking } {
    const teamsObject: { [key: string]: ITeamRanking } = {};

    teamRankingMap.forEach((teamRanking, key) =>
      Object.assign(teamsObject, { [key]: teamRanking.toObject() })
    );

    return teamsObject;
  }

  public sortBy(sortProp: SortProps): Array<TeamRanking> {
    const weeklyRanking = Array.from(this.teams.values());

    weeklyRanking.sort(
      (teamRankingA, teamRankingB) =>
        teamRankingB[sortProp] - teamRankingA[sortProp]
    );

    return weeklyRanking;
  }
}
