import { PRIMARY_COLOR } from 'src/app/constants/constants';
import { ITeamMember, TeamMember } from '../team-member/team-member';

export enum TeamVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface ITeam {
  avatar: string;
  color: string;
  id: string;
  joinRequests: Array<ITeamMember>;
  location: string;
  members: Array<ITeamMember>;
  memberIds: Array<string>;
  name: string;
  teamCaptain: string;
  visibility: string;
}

export class Team {
  public avatar: string = '';
  public color: string = PRIMARY_COLOR;
  public id: string = '';
  public joinRequests: Array<TeamMember> = [];
  public location: string = '';
  public members: Array<TeamMember> = [];
  public memberIds: Array<string> = [];
  public name: string = '';
  public teamCaptain: string = '';
  public visibility: string = TeamVisibility.PUBLIC;

  constructor(initializer?: Partial<ITeam>) {
    if (initializer) {
      Object.assign(this, initializer);

      if (initializer.joinRequests && initializer.joinRequests.length > 0) {
        this.joinRequests = initializer.joinRequests.map(
          (iTeamMember) => new TeamMember(iTeamMember)
        );
      }

      if (initializer.members && initializer.members.length > 0) {
        this.members = initializer.members.map(
          (iTeamMember) => new TeamMember(iTeamMember)
        );
      }
    }
  }
  public toObject(): ITeam {
    const { joinRequests, members, ...otherProperties } = this;
    return {
      ...otherProperties,
      joinRequests: joinRequests.map((teamMember) => teamMember.toObject()),
      members: members.map((teamMember) => teamMember.toObject()),
    };
  }

  public getTeamCaptainMember(): TeamMember | null {
    if (this.teamCaptain !== '') {
      const teamCaptainMember = this.members.find(
        (member) => member.id === this.teamCaptain
      );

      if (!teamCaptainMember) {
        this.teamCaptain = '';
        throw new Error('team captain not present in members array');
      } else {
        return teamCaptainMember;
      }
    } else {
      return null;
    }
  }

  public isFull(maxParticipants: number): boolean {
    return this.members.length >= maxParticipants;
  }

  public isPrivate(): boolean {
    return this.visibility === TeamVisibility.PRIVATE;
  }

  public isLocalizable(locations: Array<string>): boolean {
    return locations.includes(this.location);
  }

  public hasPendingRequests(): boolean {
    return this.joinRequests.length > 0;
  }
}
