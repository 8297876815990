import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from 'src/app/helpers/helpers';
import { AssetsConstants } from 'src/app/models/assets-constants';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
// eslint-disable-next-line max-len
import { ITeamChallengeDashboard } from 'src/app/shared/components/team-challenge-v2/team-challenge-dashboard/team-challenge-dashboard.component';
import { INoCurrentChallenge } from 'src/app/shared/widgets/no-current-challenge/no-current-challenge/no-current-challenge.component';

@Component({
  selector: 'app-team-challenge-container',
  templateUrl: './team-challenge-container.component.html',
  styleUrls: ['./team-challenge-container.component.scss'],
})
export class TeamChallengeContainerComponent implements OnInit {
  @Input() companyId: string;
  @Input() challengeId: string;

  public loading = true;
  public teamChallenges: Array<TeamChallengeV2>;
  public currentTeamChallengeDashboard: ITeamChallengeDashboard;
  public completedTeamChallengeDashboard: ITeamChallengeDashboard;
  public nextTeamChallenge: TeamChallengeV2;
  public completedTeamChallenges: Array<TeamChallengeV2> = [];
  public noTeamChallenge: INoCurrentChallenge = {
    image: AssetsConstants.GroupOfPeopleAndLogo,
    title: 'TEAM_CHALLENGE_V2.NO_CURRENT_CHALLENGE.TITLE',
  };

  public selectedChallengeFormControl: FormControl = new FormControl();
  public selectedChallenge: TeamChallengeV2;

  constructor(
    private route: ActivatedRoute,
    private teamChallengeService: TeamChallengeService,
    private userMessageService: UserMessageService
  ) {}

  ngOnInit(): void {
    if (!this.companyId) {
      this.companyId = this.route.snapshot.paramMap.get('companyId');
      this.challengeId = this.route.snapshot.paramMap.get('challengeId');
    }

    if (this.companyId) {
      this.teamChallengeService
        .getTeamChallengesV2(this.companyId)
        .then((teamChallenges) => {
          const today = Helpers.createISODate();
          this.teamChallenges = teamChallenges;
          this.completedTeamChallenges = teamChallenges.filter(
            (challenge) => challenge.endDate < today
          );
          let activeChallenge = teamChallenges.find((teamChallenge) =>
            teamChallenge.isInProgress()
          );
          if (!activeChallenge) {
            this.nextTeamChallenge = this.teamChallenges
              .filter((challenge) => challenge.startDate > today)
              .reverse()[0];
            activeChallenge = this.nextTeamChallenge;
          }

          if (activeChallenge) {
            // Initialization (to prepare visuals for User)
            this.teamChallengeService
              .getTeamsFromTeamChallengeV2(this.companyId, activeChallenge.id)
              .then((teams) => {
                this.currentTeamChallengeDashboard = {
                  teamChallenge: activeChallenge,
                  companyId: this.companyId,
                  teams,
                };

                // Subscription for deeper changes
                this.teamChallengeService.subscribeToTeamChallengeV2(
                  this.companyId,
                  activeChallenge.id,
                  this.currentTeamChallengeDashboard
                );
                this.loading = false;
              })
              .catch((error) => {
                console.log('getTeamsFromTeamChallengeV2 - error: ', error);
                this.userMessageService.snackBarMessage(
                  'USER_MESSAGE_SERVICE.GENERIC_ERROR'
                );
              });
          } else {
            this.loading = false;
          }

          if (this.completedTeamChallenges.length === 0) {
            this.selectedChallengeFormControl.disable({ emitEvent: false });
          }
        })
        .catch((error) => {
          console.log('getTeamChallengesV2 - error: ', error);
          this.userMessageService.snackBarMessage(
            'USER_MESSAGE_SERVICE.GENERIC_ERROR'
          );
        });
    }
  }

  // private setCompletedCitiesChallengeDashboardData(challengeId: string): void {
  //   this.completedTeamsChallengeDashboard = null;
  //   this.loading = true;
  //   this.getCitiesChallengeDashboardData(challengeId, this.companyId)
  //     .then(
  //       (citiesChallengeDashboard) =>
  //         (this.completedCitiesChallengeDashboard = citiesChallengeDashboard)
  //     )
  //     .finally(() => (this.loading = false));
  // }

  // public getCitiesChallengeDashboardData(
  //   challengeId: string,
  //   companyId: string
  // ): Promise<ICitiesChallengeDashboard> {
  //   const citiesChallenge = this.citiesChallenges.find(
  //     (challenge) => challenge.id === challengeId
  //   );
  //   const statisticsPromise = this.statisticsService.getTrainingsStatistics(
  //     companyId,
  //     StatisticsBasis.Challenges,
  //     challengeId
  //   );
  //   const citiesChallengeDailyPerformancePromise = this.citiesChallengeService.getCitiesChallengeDailyPerformances(
  //     companyId,
  //     challengeId
  //   );

  //   return Promise.all([
  //     statisticsPromise,
  //     citiesChallengeDailyPerformancePromise,
  //   ])
  //     .then(([companyTrainingData, citiesChallengeDayPerformances]) => {
  //       citiesChallengeDayPerformances.push(
  //         new CitiesChallengeDayPerformance({
  //           date: Helpers.formatDateYYYYMMDD(new Date()),
  //           ...citiesChallenge.totalPerformance.toObject(),
  //         })
  //       );
  //       return {
  //         citiesChallenge,
  //         companyTrainingData,
  //         citiesChallengeDays: citiesChallengeDayPerformances,
  //         companyId,
  //       };
  //     })
  //     .catch((error) => {
  //       console.log(
  //         'Error getting cities challenge and training data: ',
  //         error
  //       );
  //       return {
  //         citiesChallenge: null,
  //         companyTrainingData: [],
  //         citiesChallengeDays: [],
  //         companyId: null,
  //       };
  //     });
  // }
}
