import { Gender } from './gender.enum';
import { Award } from './award.model';
import { Deserializable } from './deserializable.model';
import { Roles } from './roles.enum';
import { Settings } from './settings.model';
import { TeamChallengeHistory } from './team-challenge-history.model';
import { UserFeatures } from './user-features.enum';

export const UserPropNames = {
  avatar: 'Avatar',
  awards: 'Awards',
  birthdate: 'Birthdate',
  companyId: 'Company ID',
  locationIds: 'Location IDs',
  currentChallengeId: 'Current Challenge ID',
  currentLevel: 'Current Level',
  currentLevelId: 'Current Level ID',
  currentTeamChallengeId: 'Current Team Challenge ID',
  currentTrainingId: 'Current Training ID',
  dailySteps: 'Daily Steps',
  displayName: 'Name',
  email: 'Email',
  gender: 'Gender',
  hasUnreadNotifications: 'Notifications Unread?',
  height: 'Height',
  id: 'ID',
  lastSync: 'Last Sync',
  nickname: 'Nickname',
  notificationToken: 'Notification Token',
  physicalCondition: 'Physical Condition accepted?',
  termsAndConditionsAcceptance: 'Terms and Conditions accepted?',
  shareDataAcceptance: 'Share Data accepted?',
  role: 'Role',
  settings: 'Settings',
  teamChallengesHistory: 'Team Challenges History',
  teamId: 'Team ID',
  timezoneOffset: 'Timezone Offset',
  wallet: 'Wallet',
  weight: 'Weight',
  isParticipatingInDuels: 'Participates in Duels?',
  'settings.healthProvider': 'Health Provider',
  'settings.lang': 'Lang',
};

export interface IUserInitializer {
  activityIds?: Array<string>;
  avatar?: string;
  awards?: Array<Award>;
  birthdate?: string;
  companyId?: string;
  currentChallengeId?: string;
  currentLevel?: number;
  currentLevelId?: string;
  currentTeamChallengeId?: string;
  currentTrainingId?: string;
  dailySteps?: number;
  displayName?: string;
  email?: string;
  features?: Array<string>;
  gender?: Gender;
  hasUnreadNotifications?: boolean;
  healthProviderToken?: string;
  height?: number;
  id?: string;
  isAvatarVisible?: boolean;
  isParticipatingInDuels?: boolean;
  lastSync?: number;
  locationIds?: Array<string>;
  nickname?: string;
  notificationToken?: string;
  physicalCondition?: boolean;
  referralProgramCode?: string;
  referralProgramConditionsAcceptationDate?: string;
  role?: Roles;
  settings?: Settings;
  shareDataAcceptance?: string;
  shareEmailAcceptance?: string;
  teamChallengesHistory?: Array<TeamChallengeHistory>;
  teamId?: string;
  termsAndConditionsAcceptance?: string;
  timezoneOffset?: number;
  wallet?: number;
  weight?: number;
}

export class User implements Deserializable {
  public activityIds: Array<string> = [];
  public avatar = 'assets/img/move-me-new.png';
  public awards: Array<Award> = [];
  public birthdate: string = null;
  public companyId: string = null;
  public currentChallengeId: string = null;
  public currentLevel: number = null;
  public currentLevelId: string = null;
  public currentTeamChallengeId: string = null;
  public currentTrainingId: string = null;
  public dailySteps: number = null;
  public displayName: string = null;
  public email: string = null;
  public features: Set<string> = new Set<string>();
  public gender: Gender = null;
  public hasUnreadNotifications = false;
  public healthProviderToken: string = null;
  public height: number = null;
  public id: string = null;
  public isAvatarVisible = false;
  public isParticipatingInDuels = false;
  public lastSync: number = null;
  public locationIds: Array<string> = [];
  public nickname: string = null;
  public notificationToken: string = null;
  public physicalCondition = false;
  public referralProgramCode = null;
  public referralProgramConditionsAcceptationDate = null;
  public role: Roles = Roles.User;
  public settings: Settings = new Settings();
  public shareDataAcceptance = false;
  public shareEmailAcceptance: string = null;
  public teamChallengesHistory: Array<TeamChallengeHistory> = [];
  public teamId: string = null;
  public termsAndConditionsAcceptance = false;
  public timezoneOffset: number = null;
  public wallet = 0;
  public weight: number = null;

  constructor(initializer?: IUserInitializer) {
    Object.assign(this, initializer);
    if (initializer && initializer.email) {
      this.email = initializer.email.toLowerCase().trim();
    }
    if (initializer && initializer.awards && initializer.awards.length > 0) {
      this.awards = initializer.awards.map((award) => new Award(award));
    }
    this.settings = new Settings(initializer?.settings);
    if (
      initializer &&
      initializer.teamChallengesHistory &&
      initializer.teamChallengesHistory.length > 0
    ) {
      this.teamChallengesHistory = initializer.teamChallengesHistory.map(
        (teamChallengeHistory) => new TeamChallengeHistory(teamChallengeHistory)
      );
    }
    if (initializer && initializer.features) {
      this.features = new Set(initializer.features);
    }
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input && input.awards && input.awards.length > 0) {
      this.awards = input.awards.map((award) => new Award(award));
    }

    if (
      input &&
      input.teamChallengesHistory &&
      input.teamChallengesHistory.length > 0
    ) {
      this.teamChallengesHistory = input.teamChallengesHistory
        .map((teamChallengesHistory) =>
          new TeamChallengeHistory().deserialize(teamChallengesHistory)
        )
        .sort(
          (
            teamChallengeA: TeamChallengeHistory,
            teamChallengeB: TeamChallengeHistory
          ) => teamChallengeB.endDate - teamChallengeA.endDate
        );
    }

    if (input.settings) {
      this.settings = new Settings(input.settings);
    } else {
      this.settings = new Settings();
    }

    if (input && input.features) {
      this.features = new Set(input.features);
    }

    return this;
  }

  toObject(): User {
    const awards = this.awards.map((award) => award.toObject());
    const teamChallengesHistory = this.teamChallengesHistory
      .map((teamChallengeHistory) => teamChallengeHistory.toObject())
      .sort(
        (teamChallengeA, teamChallengeB) =>
          teamChallengeB.endDate - teamChallengeA.endDate
      );
    const settings = this.settings ? this.settings.toObject() : null;
    const features = Array.from(this.features);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, {
      awards,
      teamChallengesHistory,
      settings,
      features,
    });
  }

  public hasFeature(featureToSearch: UserFeatures): boolean {
    return this.features.has(featureToSearch);
  }
}
