import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Helpers } from 'src/app/helpers/helpers';
import { IconNames } from 'src/app/models/assets-constants';
import { Company } from 'src/app/models/company.model';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge';
import {
  SortProps,
  WeeklyHistory,
} from 'src/app/models/team-challenge/weekly-history/weekly-history';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
import { IKpiList } from 'src/app/shared/widgets/kpi-list/kpi-list.component';
import {
  IUserRanking,
  IUserRankingItem,
} from 'src/app/shared/widgets/user-ranking/user-ranking.component';
import {
  iTeamChallenge1,
  iWeeklyHistory,
} from 'src/app/helpers/mock-helpers/team-challenge';
import { TeamRankingBy } from '../team-ranking-table/team-ranking-table.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { de, enGB } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';

export interface ITeamChallengeOverview {
  teamChallenge: TeamChallengeV2;
  weeklyHistory: WeeklyHistory;
  company: Company;
}

export const RANKING_TABS = {
  ACCUMULATED_POINTS: 0,
  AVERAGE_PERFORMANCE: 1,
};

const DATEPICKER_FORMAT = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd.MM.yyyy',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

@Component({
  selector: 'app-team-challenge-overview',
  templateUrl: './team-challenge-overview.component.html',
  styleUrls: ['./team-challenge-overview.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT },
  ],
})
export class TeamChallengeOverviewComponent implements OnInit {
  // TODO: Remove mock data after adding connections with database
  @Input() teamChallengeOverview: ITeamChallengeOverview = {
    teamChallenge: new TeamChallengeV2(iTeamChallenge1),
    weeklyHistory: new WeeklyHistory(iWeeklyHistory),
    company: new Company({ id: 'Move Me' }),
  };

  public dateFormControl: FormControl = new FormControl();
  public maxDate: string;
  public today = Helpers.createISODateTime();
  public downloading = false;
  public challengeKpiList: IKpiList;
  public pointsRanking: IUserRanking;
  public performanceRanking: IUserRanking;
  public selectedTab = RANKING_TABS.ACCUMULATED_POINTS;
  public teamRankingBy = TeamRankingBy;

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private reportsService: ReportsService,
    private userMessageService: UserMessageService
  ) {}

  // TODO: After removing mock data, test if the component updates correctly or if we have to implement an onChanges
  ngOnInit(): void {
    this.setLocaleToDateAdapter();
    this.maxDate = this.teamChallengeOverview.teamChallenge.isInProgress()
      ? this.today
      : this.teamChallengeOverview.teamChallenge.endDate;

    this.dateFormControl.disable();
    this.dateFormControl.setValue(this.maxDate);

    this.setKpiLists();
    this.setPointsRanking();
    this.setPerformanceRanking();

    this.translateService.onLangChange.subscribe(() => {
      this.setLocaleToDateAdapter();
    });
  }

  public getChallengeStatistic(event): void {
    // TODO: create Logic to get the correct data.
    console.log('Getting correct weekly data', new Date(event.value));
  }

  private setKpiLists(): void {
    this.challengeKpiList = {
      title: 'KPI_LIST.TEAM_CHALLENGE.TITLE_OVERVIEW',
      list: [
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_PARTICIPANTS',
          value: 2000,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_STEPS',
          value: 6,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_KM',
          value: 50,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.ACTIVE_PARTICIPANTS',
          value: 2000,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_STEPS_AVERAGE',
          value: 6,
        },
        {
          name: 'KPI_LIST.TEAM_CHALLENGE.TOTAL_SYNCS',
          value: 50,
        },
      ],
    };
  }

  private setPointsRanking(): void {
    const ranking: Array<IUserRankingItem> = this.teamChallengeOverview.weeklyHistory
      .sortBy(SortProps.points)
      .map((teamRanking) => ({
        avatar: teamRanking.teamAvatar,
        nickname: teamRanking.teamName,
        performance: teamRanking.points,
      }));
    this.pointsRanking = {
      title: 'USER_RANKING.TEAM_CHALLENGE.POINTS_TITLE',
      subtitle: 'USER_RANKING.TEAM_CHALLENGE.POINTS_SUBTITLE',
      ranking,
      badgeIcons: [
        IconNames.FirstPlaceTeamChallenge,
        IconNames.SecondPlaceTeamChallenge,
        IconNames.ThirdPlaceTeamChallenge,
      ],
      emptyRankingMessage: 'USER_RANKING.TEAM_CHALLENGE.POINTS_EMPTY_MESSAGE',
    };
  }

  private setPerformanceRanking(): void {
    const ranking: Array<IUserRankingItem> = this.teamChallengeOverview.weeklyHistory
      .sortBy(SortProps.totalAveragePerformance)
      .map((teamRanking) => ({
        avatar: teamRanking.teamAvatar,
        nickname: teamRanking.teamName,
        performance: teamRanking.totalAveragePerformance,
      }));
    this.performanceRanking = {
      title: 'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_TITLE',
      subtitle: 'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_SUBTITLE',
      ranking,
      badgeIcons: [],
      emptyRankingMessage:
        'USER_RANKING.TEAM_CHALLENGE.PERFORMANCE_EMPTY_MESSAGE',
    };
  }

  private setLocaleToDateAdapter(): void {
    if (this.translateService.currentLang === 'en') {
      this.dateAdapter.setLocale(enGB);
    } else {
      this.dateAdapter.setLocale(de);
    }
  }

  public downloadReport(): void {
    // this.downloading = true;
    // this.reportsService
    //   .generateTeamReport(
    //     this.teamChallengeOverview.company,
    //     this.teamChallengeOverview.teamChallenge,
    //     this.teamChallengeOverview.statistics,
    //   )
    //   .then((blob) => this.reportsService.openBlob(blob))
    //   .catch((error) => {
    //     console.log(
    //       'Cities challenge dashboard - Error downloading report: ',
    //       error
    //     );
    //     this.userMessageService.snackBarMessage(
    //       'CITIES_CHALLENGE.REPORT.ERROR_CREATING_FILE'
    //     );
    //   })
    //   .finally(() => (this.downloading = false));
  }
}
