import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { TeamChallengeTypes } from 'src/app/models/team-challenge-types.enum';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Company } from 'src/app/models/company.model';
import { Team } from 'src/app/models/team.model';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EmailSendResult,
  EmailType,
  IEmailData,
  NewEmailFormComponent,
} from 'src/app/shared/components/new-email-form/new-email-form.component';
import {
  BlobFile,
  ReportsService,
} from 'src/app/services/reports/reports.service';
import { ONE_PAGER } from 'src/app/constants/constants';
import { IconNames } from 'src/app/models/assets-constants';

@Component({
  selector: 'app-edit-team-challenge',
  templateUrl: './edit-team-challenge.component.html',
  styleUrls: ['./edit-team-challenge.component.scss'],
})
export class EditTeamChallengeComponent implements OnInit {
  public challengeDataForm: UntypedFormGroup;
  public teamsDataForm: UntypedFormGroup;
  public pointsAwardedDataForm: UntypedFormGroup;
  public teamCaptainsCheckboxesForm: UntypedFormGroup = new UntypedFormGroup({
    all: new UntypedFormControl(false),
  });

  public showSpinner = false;
  public showSendEmailSpinner = false;

  public companyId: string;
  public company: Company;

  public allTeams: Array<Team> = [];

  public companyTeams: Array<Team> = [];
  public selectableTeams: Array<Team> = [];
  public challengeTeams: Array<Team> = [];

  public teamsDataSource = new MatTableDataSource(this.challengeTeams);

  private teamChallengeId: string;
  public teamChallenge: TeamChallenge;

  public teamIds = [];

  public teamsColumns: Array<string> = [
    'avatar',
    'name',
    'totalPoints',
    'totalSteps',
    'teamCaptain',
    'delete',
    'checkbox',
  ];

  public pointsAwarded = []; // [{position, points}] => convert to map
  public pointsAwardedDataSource = new MatTableDataSource();
  public pointsAwardedColumns: Array<string> = ['position', 'points', 'delete'];

  public dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public challengeTypesNames: Array<string> = Object.keys(TeamChallengeTypes)
    .map((key) => TeamChallengeTypes[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public sentEmailOkIcon = IconNames.SentEmailOk;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private firebaseService: FirebaseService,
    private teamsService: TeamsService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private reportsService: ReportsService
  ) {
    this.challengeDataForm = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      duration: [4, Validators.required],
      participantsPerTeam: [10, Validators.required],
      type: ['0', Validators.required],
    });

    this.teamsDataForm = this.formBuilder.group({
      teamId: ['', Validators.required],
    });

    this.pointsAwardedDataForm = this.formBuilder.group({
      position: [1, [Validators.required, Validators.min(1)]],
      points: [3, [Validators.required, Validators.min(1)]],
    });
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.challengeDataForm.controls.companyId.setValue(this.companyId);
    this.teamChallengeId = this.route.snapshot.paramMap.get('teamChallengeId');

    Promise.all([
      this.firebaseService.getTeamChallenge(
        this.companyId,
        this.teamChallengeId
      ),
      this.firebaseService.getTeamSchemas(),
      this.teamsService.getTeams(this.companyId),
      this.companyService.getCompany(this.companyId),
    ])
      .then(([teamChallengeSnapshot, teamSchemas, companyTeams, company]) => {
        this.allTeams = teamSchemas.docs.map(
          (teamSchema) => new Team(teamSchema.data())
        );
        this.companyTeams = companyTeams;
        const allTeamsIds = this.allTeams.map((team) => team.id);
        this.allTeams.push(
          ...this.companyTeams.filter((team) => !allTeamsIds.includes(team.id))
        );
        this.company = company;
        this.selectableTeams = this.allTeams.filter(
          (team) => !this.company.teamIds.includes(team.id)
        );

        this.teamChallenge = new TeamChallenge(teamChallengeSnapshot.data());
        this.initializeTeams();
        this.initializePointsAwarded();
        Object.entries(this.challengeDataForm.controls).forEach((control) => {
          if (control[0] !== 'companyId') {
            this.challengeDataForm
              .get(control[0])
              .setValue(
                control[0] !== 'type'
                  ? this.teamChallenge[control[0]]
                  : this.teamChallenge[control[0]].toString()
              );
          }
        });

        this.dateRange
          .get('start')
          .setValue(new Date(this.teamChallenge.startDate));
        this.dateRange
          .get('end')
          .setValue(new Date(this.teamChallenge.endDate));
      })
      .catch((error) => {
        console.log('ngOnInit Promises - error: ', error);
      });
  }

  private initializeTeams(): void {
    this.teamIds = Array.from(this.teamChallenge.teamIds);
    this.challengeTeams = this.companyTeams.filter((companyTeam) =>
      this.teamIds.includes(companyTeam.id)
    );
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
    this.challengeTeams.forEach((team) => {
      if (!this.teamChallenge.teamCaptainWithEmailSent.has(team.teamCaptain)) {
        this.teamCaptainsCheckboxesForm.addControl(
          team.id,
          new UntypedFormControl({ value: false, disabled: !team.teamCaptain })
        );

        this.teamCaptainsCheckboxesForm.controls[
          team.id
        ].valueChanges.subscribe((value) => {
          if (!value && this.teamCaptainsCheckboxesForm.controls.all.value) {
            this.teamCaptainsCheckboxesForm.controls.all.setValue(false);
          }
        });
      }
    });

    if (
      this.challengeTeams.every(
        (team) =>
          !team.teamCaptain ||
          this.teamChallenge.teamCaptainWithEmailSent.has(team.teamCaptain)
      )
    ) {
      this.teamCaptainsCheckboxesForm.controls.all.disable();
    }

    this.selectableTeams = this.selectableTeams.filter(
      (team) => !this.teamIds.includes(team.id)
    );
  }

  public addTeam(): void {
    const teamId = this.teamsDataForm.controls.teamId.value;
    if (this.company.teamIds.includes(teamId)) {
      this.challengeTeams.push(
        ...this.companyTeams.filter((team) => team.id === teamId)
      );
    } else {
      this.challengeTeams.push(
        ...this.allTeams.filter((team) => team.id === teamId)
      );
    }

    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);

    const teamAdded = this.challengeTeams.find(
      (challengeTeam) =>
        challengeTeam.id === this.teamsDataForm.controls.teamId.value
    );

    this.teamCaptainsCheckboxesForm.addControl(
      teamId,
      new UntypedFormControl({ value: false, disabled: !teamAdded.teamCaptain })
    );

    this.teamCaptainsCheckboxesForm.controls[teamId].valueChanges.subscribe(
      (value) => {
        if (!value && this.teamCaptainsCheckboxesForm.controls.all.value) {
          this.teamCaptainsCheckboxesForm.controls.all.setValue(false);
        }
      }
    );

    this.selectableTeams.splice(
      this.selectableTeams.findIndex(
        (team) => team.id === this.teamsDataForm.controls.teamId.value
      ),
      1
    );
    this.teamsDataForm.controls.teamId.setValue('');
  }

  public removeTeam(teamId: string): void {
    this.challengeTeams.splice(
      this.challengeTeams.findIndex((team) => team.id === teamId),
      1
    );
    this.selectableTeams.push(this.allTeams.find((team) => team.id === teamId));
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
    this.teamCaptainsCheckboxesForm.removeControl(teamId);
  }

  private initializePointsAwarded(): void {
    this.teamChallenge.pointsAwarded.forEach((value, key) => {
      this.pointsAwarded.push({ position: Number(key), points: value });
    });
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public addPointsAwarded(): void {
    if (
      !this.pointsAwarded.find(
        (pointsAward) =>
          pointsAward.position ===
          this.pointsAwardedDataForm.controls.position.value
      )
    ) {
      this.pointsAwarded.push({
        position: this.pointsAwardedDataForm.controls.position.value,
        points: this.pointsAwardedDataForm.controls.points.value,
      });
      this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
    }
  }

  public removePointsAward(pointsAward: {
    position: number;
    points: number;
  }): void {
    this.pointsAwarded.splice(
      this.pointsAwarded.findIndex(
        (element) => element.position === pointsAward.position
      ),
      1
    );
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public async updateTeamChallenge(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;

    if (this.challengeDataForm.valid) {
      const challengeId = this.challengeDataForm.controls.id.value;
      const companyId = this.challengeDataForm.controls.companyId.value;

      const pointsAwarded = {};
      this.pointsAwarded.forEach((pointsAward) => {
        pointsAwarded[pointsAward.position] = pointsAward.points;
      });

      const teamIds = new Set(this.challengeTeams.map((team) => team.id));

      const teamChallenge = new TeamChallenge({
        id: challengeId,
        name: this.challengeDataForm.controls.name.value,
        type: this.challengeDataForm.controls.type.value,
        participantsPerTeam: this.challengeDataForm.controls.participantsPerTeam
          .value,
        duration: this.challengeDataForm.controls.duration.value,
        pointsAwarded,
        teamIds,
        startDate: new Date(this.dateRange.value.start).getTime(),
        endDate: new Date(this.dateRange.value.end).getTime(),
        currentWeek: this.teamChallenge.currentWeek,
        userIds: this.teamChallenge.userIds,
        teamCaptainWithEmailSent: this.teamChallenge.teamCaptainWithEmailSent,
      });

      // CREATE TEAMS FROM TEAM SCHEMAS IF THEY ARE NEW TEAMS

      const teamsToCreate = this.challengeTeams.filter(
        (team) => !this.company.teamIds.includes(team.id)
      );

      if (teamsToCreate.length > 0) {
        try {
          await this.teamsService.createCompanyTeams(companyId, teamsToCreate);
        } catch (error) {
          console.log(
            'error on addTeamChallenge, while creating teams: ',
            error
          );
          this.showSpinner = false;
          this.snackBar.open(
            'error on addTeamChallenge, while creating teams: ' + error,
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
          return Promise.reject(error);
        }
      }

      try {
        await this.firebaseService.createTeamChallenge(
          companyId,
          teamChallenge,
          challengeId
        );
      } catch (error) {
        console.log(
          'error on addTeamChallenge, while creating the challenge: ',
          error
        );
        this.showSpinner = false;
        this.snackBar.open(
          'error on addTeamChallenge, while creating the challenge: ' + error,
          'Ok',
          {
            duration: 4000,
            panelClass: 'snack-bar-color',
          }
        );
        return Promise.reject(error);
      }

      this.showSpinner = false;
      const updateTeamChallengeMessage: string = this.translateService.instant(
        'ADD_PERSONAL_CHALLENGE_CREATED'
      );
      this.snackBar.open(updateTeamChallengeMessage, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });

      this.returnPage();
    }
  }

  public returnPage(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  public async sendEmailToTeamCaptains(): Promise<void> {
    this.showSendEmailSpinner = true;
    // GENERATE FILES
    const documentTitle =
      this.teamChallenge.name.replace(' ', '-') + '_Captain.pdf';

    await this.reportsService
      .generateTeamOnePagerReport(
        this.company,
        documentTitle,
        this.teamChallenge,
        this.translateService.instant('reports'),
        ONE_PAGER.TEAM_TEMPLATE_PATH
      )
      .then((blobFiles: Array<BlobFile>) => {
        // SHOW EMAIL - send to component: emails, body, subject, and attachments

        const attachmentsAndRecipients = blobFiles
          .map((blobFile) => {
            const blobTeam = this.challengeTeams.find(
              (team) =>
                team.id === blobFile.title.split('-')[0] &&
                this.teamCaptainsCheckboxesForm.controls[team.id] &&
                this.teamCaptainsCheckboxesForm.controls[team.id].value
            );
            if (blobTeam) {
              return {
                recipient: blobTeam.teamCaptain,
                attachment: blobFile,
              };
            }
            return null;
          })
          .filter(Boolean);

        const recipients = attachmentsAndRecipients.map(
          (attachmentAndRecipient) => attachmentAndRecipient.recipient
        );
        const attachments = attachmentsAndRecipients.map(
          (attachmentAndRecipient) => attachmentAndRecipient.attachment
        );

        const teamCaptainsEmailTranslations = this.translateService.instant(
          'EMAILS.TEAM_CAPTAINS_ONE_PAGER'
        );

        const emailData: IEmailData = {
          subject: teamCaptainsEmailTranslations.DEFAULT_SUBJECT,
          body: teamCaptainsEmailTranslations.DEFAULT_BODY,
          attachments,
          recipients,
          type: EmailType.OneAttachmentPerRecipient,
        };
        this.showSendEmailSpinner = false;
        const newEmailDialog = this.dialog.open(NewEmailFormComponent, {
          width: '800px',
          data: emailData,
          disableClose: true,
        });

        newEmailDialog.afterClosed().subscribe((result: EmailSendResult) => {
          if (result === EmailSendResult.Sent) {
            Object.keys(this.teamCaptainsCheckboxesForm.controls).forEach(
              (key) => {
                this.teamCaptainsCheckboxesForm.controls[key].setValue(false);
                this.teamCaptainsCheckboxesForm.controls[key].disable();
              }
            );
            for (const recipient of recipients) {
              this.teamChallenge.teamCaptainWithEmailSent.add(recipient);
              const recipientTeam = this.challengeTeams.find(
                (team) => team.teamCaptain === recipient
              );
              this.teamCaptainsCheckboxesForm.removeControl(recipientTeam.id);
            }
            this.firebaseService.updateTeamChallenge(
              this.companyId,
              this.teamChallenge,
              this.teamChallenge.id
            );
          }
        });
      })
      .catch((error) => {
        // TODO: show error
        console.log('error: ', error);
      });
  }

  public getSendEmailButtonDisable(): boolean {
    return (Object.values(
      this.teamCaptainsCheckboxesForm.value
    ) as Array<boolean>).reduce(
      (previousValue: boolean, currentValue: boolean) =>
        previousValue || currentValue
    );
  }

  public toggleAllCheckboxes(): void {
    Object.keys(this.teamCaptainsCheckboxesForm.controls).forEach((key) => {
      if (this.teamCaptainsCheckboxesForm.controls[key].enabled) {
        this.teamCaptainsCheckboxesForm.controls[key].setValue(
          this.teamCaptainsCheckboxesForm.controls.all.value
        );
      }
    });
  }

  public calculatePointsAwarded(): void {
    this.pointsAwarded = this.challengeTeams.map((team, index, array) => ({
      position: index + 1,
      points: array.length - index,
    }));

    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }
}
