import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';
import {
  IFeatureChange,
  IFeatureSelectionCard,
} from 'src/app/shared/components/feature-selection-card/feature-selection-card.component';
import { Features as TeamChallengeFeatures } from 'src/app/models/team-challenge/features';
import { TabIndex } from '../../company-info/company-info.component';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company.model';
import { Features } from 'src/app/models/features.enum';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge';
import { Location } from 'src/app/models/location.model';
import {
  IHierarchyLevelName,
  LocationService,
} from 'src/app/services/location/location.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';

@Component({
  selector: 'app-new-team-challenge',
  templateUrl: './new-team-challenge.component.html',
  styleUrls: ['./new-team-challenge.component.scss'],
})
export class NewTeamChallengeComponent implements OnInit {
  @ViewChild('newTeamChallengeStepper') stepper: MatStepper;

  public companyId: string;
  public teamChallengeId: string;
  public company: Company;
  public features: Array<IFeatureSelectionCard> = [];

  public challengeDataForm: UntypedFormGroup;
  public availableLocationLevels: Array<IHierarchyLevelName> = [];
  public companyLoading = true;
  public teamChallengeLoading = false;
  public checkFormValidation = false;
  public isLastStep = false;
  public showSpinner = false;

  public teamChallenge: TeamChallengeV2;
  public locationsArray: Array<Location> = [];
  public locationSelectedName: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamChallengeService: TeamChallengeService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userMessageService: UserMessageService
  ) {
    this.challengeDataForm = this.teamChallengeService.getStandardTeamChallengeDataForm();
  }

  get teamChallengeFeatures(): Array<string> {
    return this.challengeDataForm.controls.features.value;
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.teamChallengeId = this.route.snapshot.paramMap.get(
      'teamChallengeV2Id'
    );
    let teamChallengePromise: Promise<TeamChallengeV2>;

    if (this.teamChallengeId) {
      this.teamChallengeLoading = true;
      teamChallengePromise = this.teamChallengeService
        .getTeamChallengeV2(this.companyId, this.teamChallengeId)
        .then((teamChallenge) => {
          if (teamChallenge) {
            this.teamChallenge = teamChallenge;
            this.teamChallengeService
              .getTeamsFromTeamChallengeV2(this.companyId, teamChallenge.id)
              .then((teams) => {
                this.teamChallengeService.setTeamChallengeToForm(
                  this.challengeDataForm,
                  teamChallenge,
                  teams
                );
                this.teamChallengeLoading = false;
              })
              .catch((error) => {
                console.log('getTeamsFromTeamChallengeV2 - error: ', error);
                this.userMessageService.snackBarMessage(
                  'USER_MESSAGE_SERVICE.GENERIC_ERROR'
                );
              });
          } else {
            this.teamChallengeLoading = false;
          }
          return teamChallenge;
        })
        .catch((error) => {
          console.log('getTeamChallengeV2 - error: ', error);
          this.userMessageService.snackBarMessage(
            'USER_MESSAGE_SERVICE.GENERIC_ERROR'
          );
          return null;
        });
    }

    const companyPromise = this.companyService
      .getCompany(this.companyId)
      .then((company) => {
        this.company = company;
        return company;
      })
      .catch((error) => {
        this.company = null;
        this.availableLocationLevels = [];
        console.log('error: ', error);
        return this.company;
      })
      .finally(() => {
        this.companyLoading = false;
      });

    Promise.all([companyPromise, teamChallengePromise])
      .then(([company, teamChallenge]) => {
        this.features = Object.values(TeamChallengeFeatures).map((feature) => ({
          feature,
          disabled:
            feature === TeamChallengeFeatures.LOCATIONS &&
            !company.features.has(Features.Locations),
          inactiveSection: {
            title: `TEAM_CHALLENGE.FEATURES.${feature}.INACTIVE.TITLE`,
            text: `TEAM_CHALLENGE.FEATURES.${feature}.INACTIVE.TEXT`,
          },
          activeSection: {
            title: `TEAM_CHALLENGE.FEATURES.${feature}.ACTIVE.TITLE`,
            text: `TEAM_CHALLENGE.FEATURES.${feature}.ACTIVE.TEXT`,
          },
          checked: teamChallenge ? teamChallenge.features.has(feature) : false,
        }));
      })
      .catch((error) => {
        console.log('company or teamChallengePromise - error: ', error);
        this.userMessageService.snackBarMessage(
          'USER_MESSAGE_SERVICE.GENERIC_ERROR'
        );
      });
  }

  public goToCompanyInfo(): void {
    const returnPath = this.teamChallengeId ? '../..' : '..';
    this.router.navigate([returnPath], {
      relativeTo: this.route,
      state: { companyInfoTab: TabIndex.TeamChallengesV2 },
    });
  }

  public goBack(): void {
    this.checkFormValidation = false;
    if (this.stepper.selectedIndex === 0) {
      this.goToCompanyInfo();
    } else if (this.stepper.selectedIndex === 2) {
      this.checkFormValidation = true;
      this.teamChallengeService.setFeaturesToForm(
        this.challengeDataForm,
        this.challengeDataForm.controls.features.value
      );
      this.isLastStep = false;
      this.stepper.previous();
    } else {
      this.stepper.previous();
    }
  }

  public async goForth(): Promise<void> {
    if (this.isLastStep) {
      this.showSpinner = true;

      this.teamChallengeService
        .saveTeamChallengeFromForm(this.companyId, this.challengeDataForm)
        .then(() => {
          this.showSpinner = false;
          this.goToCompanyInfo();
        })
        .catch((error) => {
          this.showSpinner = false;
          console.log('saveTeamChallengeFromForm - error: ', error);
          this.userMessageService.snackBarMessage(
            'USER_MESSAGE_SERVICE.GENERIC_ERROR'
          );
          return null;
        });
    } else {
      this.checkFormValidation = false;
      if (this.stepper.selectedIndex === 0) {
        this.checkFormValidation = true;
        if (
          this.teamChallengeFeatures.includes(TeamChallengeFeatures.LOCATIONS)
        ) {
          this.availableLocationLevels = await this.locationService.getTopHierarchyLocationsNames(
            this.companyId,
            this.company.locationsInfo
          );
        }
      }
      if (this.stepper.selectedIndex === 1) {
        this.teamChallenge = new TeamChallengeV2(
          this.challengeDataForm.getRawValue()
        );
        this.challengeDataForm.controls.teamForms.disable();

        if (this.teamChallenge.hasFeature(TeamChallengeFeatures.LOCATIONS)) {
          this.locationSelectedName = this.availableLocationLevels.find(
            (element) =>
              element.hierarchyLevel ===
              this.challengeDataForm.value.locationLevel
          ).name;
          this.locationsArray = await this.locationService.getLocationByHierarchyLevel(
            this.companyId,
            this.teamChallenge.locationLevel
          );
        } else {
          this.locationSelectedName = '';
          this.locationsArray = [];
        }

        this.isLastStep = true;
      }
      this.stepper.next();
      this.changeDetectorRef.detectChanges();
    }
  }

  public toggleFeature(event: IFeatureChange): void {
    if (event.value) {
      if (!this.teamChallengeFeatures.includes(event.feature)) {
        this.challengeDataForm.patchValue({
          features: [...this.teamChallengeFeatures, event.feature],
        });
      }
    } else {
      if (this.teamChallengeFeatures.includes(event.feature)) {
        this.challengeDataForm.patchValue({
          features: this.teamChallengeFeatures.filter(
            (feature) => feature !== event.feature
          ),
        });
      }
    }
  }
}
