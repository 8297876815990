import { IWeeklyHistory } from 'src/app/models/team-challenge/weekly-history/weekly-history';
import {
  IDayPerformance,
  ITeamRanking,
} from '../../models/team-challenge/team-ranking/team-ranking';
import { ITeamMember } from 'src/app/models/team-challenge/team-member/team-member';
import { ITeam, TeamVisibility } from 'src/app/models/team-challenge/team/team';
import { ITeamChallengeInitializer } from 'src/app/models/team-challenge/team-challenge/team-challenge';
import {
  Features,
  Features as TeamChallengeFeatures,
} from 'src/app/models/team-challenge/features';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PRIMARY_COLOR, REGEX_NUMERIC } from 'src/app/constants/constants';

export const dayPerformance1: IDayPerformance = {
  syncs: 10,
  performance: 80000,
};

export const dayPerformance2: IDayPerformance = {
  syncs: 5,
  performance: 30000,
};

export const dayPerformance3: IDayPerformance = {
  syncs: 0,
  performance: 0,
};

export const dayPerformance4: IDayPerformance = {
  syncs: 1,
  performance: 10000,
};

export const dailyPerformance1: { [day: string]: IDayPerformance } = {
  '2024-05-01': dayPerformance1,
  '2024-05-02': dayPerformance2,
  '2024-05-03': dayPerformance3,
};

export const dailyPerformance2: { [day: string]: IDayPerformance } = {
  '2024-05-01': dayPerformance1,
  '2024-05-02': dayPerformance2,
  '2024-05-03': dayPerformance4,
};

export const iTeamRanking1: ITeamRanking = {
  average: 7333.33,
  dailyPerformance: dailyPerformance1,
  performance: 110000,
  points: 5,
  positionByPoints: 1,
  positionByAverage: 4,
  syncs: 15,
  teamAvatar: 'test avatar',
  teamColor: 'test color',
  teamId: 'test id',
  teamLocation: 'test location',
  teamName: 'test name',
  teamSize: 10,
  totalAveragePerformance: 5000,
  totalPerformance: 500000,
  totalSyncs: 100,
};

export const iTeamRanking2: ITeamRanking = {
  average: 7500,
  dailyPerformance: dailyPerformance2,
  performance: 120000,
  points: 4,
  positionByPoints: 2,
  positionByAverage: 3,
  syncs: 16,
  teamAvatar: 'test avatar',
  teamColor: 'test color',
  teamId: 'test id',
  teamLocation: 'test location',
  teamName: 'test name',
  teamSize: 10,
  totalAveragePerformance: 6000,
  totalPerformance: 600000,
  totalSyncs: 100,
};

export const iTeamRanking3: ITeamRanking = {
  average: 7500,
  dailyPerformance: dailyPerformance2,
  performance: 120000,
  points: 4,
  positionByPoints: 2,
  positionByAverage: 3,
  syncs: 16,
  teamAvatar: 'test avatar',
  teamColor: 'test color',
  teamId: 'test id',
  teamLocation: '',
  teamName: 'test name',
  teamSize: 10,
  totalAveragePerformance: 6000,
  totalPerformance: 600000,
  totalSyncs: 100,
};

export const iWeeklyHistory: IWeeklyHistory = {
  teams: {
    teamId1: iTeamRanking1,
    teamId2: iTeamRanking2,
  },
  weekdays: ['2024-05-01', '2024-05-02', '2024-05-03'],
};

export const iTeamMember1: ITeamMember = {
  avatar: 'test avatar for team member 1',
  email: 'test email for team member 1',
  id: 'test id for team member 1',
  nickname: 'test nickname for team member 1',
};

export const iTeamMember2: ITeamMember = {
  avatar: 'test avatar for team member 2',
  email: 'test email for team member 2',
  id: 'test id for team member 2',
  nickname: 'test nickname for team member 2',
};

export const iTeamMember3: ITeamMember = {
  avatar: 'test avatar for team member 3',
  email: 'test email for team member 3',
  id: 'test id for team member 3',
  nickname: 'test nickname for team member 3',
};

export const iTeamCaptain1: ITeamMember = {
  avatar: 'test avatar for team member 2',
  email: 'test email for team member 2',
  id: 'test id for team member 2',
  nickname: 'test nickname for team member 2',
};

export const publicTeam: ITeam = {
  avatar: 'test avatar for team 1',
  color: 'test color for team 1',
  id: 'test id for team 1',
  joinRequests: [],
  location: 'test location for team 1',
  members: [iTeamCaptain1, iTeamMember1, iTeamMember2],
  memberIds: [iTeamCaptain1.id, iTeamMember1.id, iTeamMember2.id],
  name: 'test name for team 1',
  teamCaptain: '',
  visibility: TeamVisibility.PUBLIC,
};

export const privateTeam: ITeam = {
  avatar: 'test avatar for team 1',
  color: 'test color for team 1',
  id: 'test id for team 1',
  joinRequests: [iTeamMember3],
  location: 'test location for team 1',
  members: [iTeamCaptain1, iTeamMember1, iTeamMember2],
  memberIds: [iTeamCaptain1.id, iTeamMember1.id, iTeamMember2.id],
  name: 'test name for team 1',
  teamCaptain: iTeamCaptain1.id,
  visibility: TeamVisibility.PRIVATE,
};

export const iTeamChallenge1: ITeamChallengeInitializer = {
  id: 'test team challenge name id',
  name: 'test team challenge name',
  startDate: '2024-05-01T00:00:00',
  endDate: '2024-05-28T23:59:59',
  features: [TeamChallengeFeatures.LOCATIONS],
  projectPlanSent: true,
  maxParticipantsPerTeam: 3,
  membersWaitingForTeam: [iTeamMember1],
  durationInWeeks: 4,
  locationLevel: 1,
  currentWeek: 1,
};

export const iTeamChallenge2: ITeamChallengeInitializer = {
  id: 'test team challenge name id',
  name: 'test team challenge name',
  startDate: '2024-05-01T00:00:00',
  endDate: '2024-05-28T23:59:59',
  features: [],
  projectPlanSent: true,
  maxParticipantsPerTeam: 3,
  membersWaitingForTeam: [iTeamMember1],
  durationInWeeks: 4,
  locationLevel: 1,
  currentWeek: 1,
};

export const iTeam1: ITeam = {
  avatar: 'team1Avatar',
  color: 'team1Color',
  id: 'team1Id',
  joinRequests: [],
  location: 'team1Location',
  members: [],
  memberIds: [],
  name: 'team1',
  teamCaptain: '',
  visibility: TeamVisibility.PUBLIC,
};

export const iTeam2: ITeam = {
  avatar: 'team2Avatar',
  color: 'team2Color',
  id: 'team2Id',
  joinRequests: [],
  location: 'team2Location',
  members: [],
  memberIds: [],
  name: 'team2',
  teamCaptain: '',
  visibility: TeamVisibility.PUBLIC,
};

export function getTeamChallengeDataForm(
  locationsFeatureActive: boolean,
  teamSelectionFeatureActive: boolean
): UntypedFormGroup {
  const formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  const featuresArray = [
    locationsFeatureActive && Features.LOCATIONS,
    teamSelectionFeatureActive && Features.TEAM_SELECTION,
  ].filter(Boolean);

  return formBuilder.group({
    id: [''],
    name: ['', Validators.required],
    features: [featuresArray],
    durationInWeeks: [
      0,
      [
        Validators.required,
        Validators.min(4),
        Validators.max(12),
        Validators.pattern(REGEX_NUMERIC),
      ],
    ],
    startDate: new UntypedFormControl(),
    endDate: new UntypedFormControl(),
    maxParticipantsPerTeam: [0, [Validators.required, Validators.min(0)]],
    locationLevel: [null, [Validators.min(1), Validators.max(3)]],
    teamsAmount: [0, [Validators.required, Validators.min(0)]],
    teamForms: formBuilder.array([]),
  });
}

export function getStandardTeamDataForm(): UntypedFormGroup {
  const formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  return formBuilder.group({
    avatar: ['', Validators.required],
    name: ['', Validators.required],
    color: [PRIMARY_COLOR, Validators.required],
    id: [''],
    joinRequests: [[]],
    location: [''],
    members: [[]],
    membersIds: [[]],
    teamCaptain: [''],
    visibility: [TeamVisibility.PUBLIC, Validators.required],
  });
}
