import { Injectable } from '@angular/core';
import { AdminService } from '../admin.service';
import { Company } from 'src/app/models/company.model';
import { UserSearchOptions } from 'src/app/models/user-search-options.enum';
import { User, UserPropNames } from 'src/app/models/user.model';
import { FirebaseService } from '../../firebase/firebase.service';
import firebase from 'firebase/compat/app';
import { Helpers } from 'src/app/helpers/helpers';
import { TrainingData } from 'src/app/models/training-data.model';
import { Training } from 'src/app/models/training.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../excel/excel.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { CompanyStatus } from 'src/app/models/company-status.enum';
import { SELECT_ALL } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class UsersManagementService {
  public COLUMN_DATE = '';
  public COLUMN_AVERAGE = '';
  public COLUMN_STEPS = '';
  public COLUMN_USERS = '';

  public companiesToSelect: Array<Company> = [];

  public selectedCompanies = new UntypedFormControl('');
  public userEmailToSearch = new UntypedFormControl('');
  public filterValue = new UntypedFormControl('');

  public USERS_SEARCH_OPTIONS = UserSearchOptions;
  public userSearchOptionSelected = new UntypedFormControl(
    this.USERS_SEARCH_OPTIONS.ActiveCompanies
  );

  public formControlBySearchOption = {
    [this.USERS_SEARCH_OPTIONS.ActiveCompanies]: this.selectedCompanies,
    [this.USERS_SEARCH_OPTIONS.AllCompanies]: this.selectedCompanies,
    [this.USERS_SEARCH_OPTIONS.Email]: this.userEmailToSearch,
  };

  public companyOptions = {
    [this.USERS_SEARCH_OPTIONS.ActiveCompanies]: [],
    [this.USERS_SEARCH_OPTIONS.AllCompanies]: [],
  };

  public userSearchOptions: Array<string> = Object.keys(UserSearchOptions)
    .map((key) => UserSearchOptions[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public filtersForm: UntypedFormGroup = this.formBuilder.group({});

  public usersFilters: Array<string> = [...Object.keys(UserPropNames)].sort();

  public activeFilters: Array<string> = [
    'email',
    'nickname',
    'displayName',
    'dailySteps',
    'lastSync',
  ];
  public activeSort: Sort;

  public usersToShow: Array<User> = [];

  public showSpinnerUsers = false;

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.getTranslations();

    this.usersFilters.forEach((userProp) => {
      this.filtersForm.addControl(
        userProp,
        this.formBuilder.control(this.activeFilters.includes(userProp))
      );
    });

    this.filtersForm.valueChanges.subscribe((newFilters) => {
      this.activeFilters = this.usersFilters.filter(
        (filter) => newFilters[filter]
      );
    });
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  private getTranslations(): void {
    this.translateService
      .stream(['COLUMN_DATE', 'COLUMN_AVERAGE', 'COLUMN_STEPS', 'COLUMN_USERS'])
      .subscribe((values) => {
        this.COLUMN_DATE = values.COLUMN_DATE;
        this.COLUMN_AVERAGE = values.COLUMN_AVERAGE;
        this.COLUMN_STEPS = values.COLUMN_STEPS;
        this.COLUMN_USERS = values.COLUMN_USERS;
      });
  }

  public getUsersBySearchMethod(userSearchMethod: string): Promise<void> {
    if (userSearchMethod) {
      this.showSpinnerUsers = true;
      const valueToSearch = Array.isArray(
        this.formControlBySearchOption[userSearchMethod].value
      )
        ? this.formControlBySearchOption[userSearchMethod].value.filter(
            (value) => value !== SELECT_ALL
          )
        : this.formControlBySearchOption[userSearchMethod].value;
      let querySnapshotArrayPromise: Array<
        Promise<firebase.firestore.QuerySnapshot>
      >;
      switch (userSearchMethod) {
        case UserSearchOptions.Email:
          querySnapshotArrayPromise = [
            this.firebaseService.getUserByEmail(
              valueToSearch.toLowerCase().trim()
            ),
          ];
          break;
        case UserSearchOptions.AllCompanies:
          if (
            this.formControlBySearchOption[userSearchMethod].value.includes(
              SELECT_ALL
            )
          ) {
            querySnapshotArrayPromise = [this.firebaseService.getUsers()];
            break;
          }
        // eslint-disable-next-line no-fallthrough
        default:
          querySnapshotArrayPromise = this.firebaseService.getUsersFromCompanies(
            valueToSearch
          );
          break;
      }

      return Promise.all(querySnapshotArrayPromise)
        .then((querySnapshotArray: Array<firebase.firestore.QuerySnapshot>) => {
          this.usersToShow = [];
          if (querySnapshotArray.length > 0) {
            for (const querySnapshot of querySnapshotArray) {
              if (!querySnapshot.empty) {
                this.usersToShow.push(
                  ...querySnapshot.docs.map(
                    (
                      doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
                    ) => new User().deserialize(doc.data())
                  )
                );
              }
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error);
          this.usersToShow = [];
        })
        .finally(() => {
          this.showSpinnerUsers = false;
        });
    } else {
      this.getCompaniesToSelect();
      Object.values(this.formControlBySearchOption).forEach((formControl) =>
        formControl.setValue(null)
      );
      this.usersToShow = [];
    }
  }

  public async getCompaniesToSelect(): Promise<void> {
    if (this.companies.length === 0) {
      await this.adminService.getCompanies();
    }
    this.companyOptions[
      this.USERS_SEARCH_OPTIONS.AllCompanies
    ] = this.companies;
    this.companyOptions[
      this.USERS_SEARCH_OPTIONS.ActiveCompanies
    ] = this.companies.filter(
      (company) => company.status === CompanyStatus.Active
    );
    this.companiesToSelect = this.companyOptions[
      this.userSearchOptionSelected.value
    ];
  }

  public setUsersToShow(users: Array<User>): void {
    this.usersToShow = users;
  }

  public async exportUserStepAveragePerCompany(): Promise<void> {
    const userStepsAveragePerCompany = [];

    if (this.companies.length === 0) {
      await this.adminService.getCompanies();
    }

    for (const company of this.companies) {
      const oldTrainingsQuery = await this.firebaseService.getOldTrainings(
        company.id
      );
      const newTrainingsQuery = await this.firebaseService.getTrainings(
        company.id
      );
      const trainingDocs: Array<
        firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      > = [];

      if (!oldTrainingsQuery.empty) {
        trainingDocs.push(...oldTrainingsQuery.docs);
      }

      if (!newTrainingsQuery.empty) {
        if (trainingDocs.length !== 0) {
          trainingDocs.push(
            ...newTrainingsQuery.docs.filter(
              (document) =>
                trainingDocs.findIndex(
                  (trainingDoc) => trainingDoc.id === document.id
                ) === -1
            )
          );
        } else {
          trainingDocs.push(...newTrainingsQuery.docs);
        }
      }

      if (trainingDocs.length === 0) {
        continue;
      }

      const trainings = trainingDocs.map((trainingSnapshot) => {
        const training = new Training();
        training.date = trainingSnapshot.id;
        training.trainings = Object.values(trainingSnapshot.data()).map(
          (element) => {
            if (typeof element === 'number') {
              return new TrainingData({ steps: element });
            }
            return new TrainingData({
              steps: element.steps,
              nickname: element.nickname,
              country: element.country,
              avatar: element.avatar,
              location: element.location,
              locationIds: element.locationIds ? element.locationIds : null,
            });
          }
        );
        const trainingsSum = training.trainings.reduce(
          (acc, element) => acc + element.steps,
          0
        );
        const trainingsAmount = training.trainings.length;
        return Object.assign(training, { trainingsSum, trainingsAmount });
      });

      const totalTrainingsSum = trainings.reduce(
        (acc, element) => acc + element.trainingsSum,
        0
      );
      const totalTrainingsAmount = trainings.reduce(
        (acc, element) => acc + element.trainingsAmount,
        0
      );

      const totalTrainingsAverage = (
        totalTrainingsSum / totalTrainingsAmount
      ).toFixed(2);

      const trainingObject = trainings
        .map((training) => ({
          [this.COLUMN_DATE]: training.date,
          [this.COLUMN_STEPS]: training.trainingsSum.toFixed(0),
          [this.COLUMN_USERS]: training.trainingsAmount.toFixed(0),
          [this.COLUMN_AVERAGE]: (
            training.trainingsSum / training.trainingsAmount
          ).toFixed(2),
        }))
        .sort(
          (training1, training2) =>
            Helpers.getTimeFromFormattedString(training1[this.COLUMN_DATE]) -
            Helpers.getTimeFromFormattedString(training2[this.COLUMN_DATE])
        );

      const companySheet = {
        companyId: company.id,
        totalAverage: totalTrainingsAverage,
        trainings: trainingObject,
      };

      userStepsAveragePerCompany.push(companySheet);
    }

    this.excelService.exportUserStepsAverageExcel(userStepsAveragePerCompany);
  }

  public updateSort(sort: Sort): void {
    this.activeSort = sort;
  }

  public exportUserTableToExcel(
    usersDataSource: Array<Partial<User> | { sLastSync: string }>
  ): void {
    this.excelService.exportUserTableToExcel(usersDataSource);
  }
}
