export interface IDayPerformance {
  syncs: number;
  performance: number;
}

export interface ITeamRanking {
  average: number;
  dailyPerformance: { [day: string]: IDayPerformance };
  performance: number;
  points: number;
  positionByPoints: number;
  positionByAverage: number;
  syncs: number;
  teamAvatar: string;
  teamColor: string;
  teamId: string;
  teamLocation: string;
  teamName: string;
  teamSize: number;
  totalAveragePerformance: number;
  totalPerformance: number;
  totalSyncs: number;
}

export class TeamRanking {
  public average: number = 0;
  public dailyPerformance: Map<string, IDayPerformance> = new Map<
    string,
    IDayPerformance
  >();
  public performance: number = 0;
  public points: number = 0;
  public positionByPoints: number = 0;
  public positionByAverage: number = 0;
  public syncs: number = 0;
  public teamAvatar: string = '';
  public teamColor: string = '';
  public teamId: string = '';
  public teamLocation: string = '';
  public teamName: string = '';
  public teamSize: number = 0;
  public totalAveragePerformance: number = 0;
  public totalPerformance: number = 0;
  public totalSyncs: number = 0;

  constructor(initializer?: ITeamRanking) {
    if (initializer) {
      Object.assign(this, initializer);

      if (initializer.dailyPerformance) {
        this.dailyPerformance = new Map<string, IDayPerformance>(
          Object.entries(initializer.dailyPerformance)
        );
      }
    }
  }

  public toObject(): ITeamRanking {
    const { dailyPerformance, ...otherProperties } = this;
    return {
      ...otherProperties,
      dailyPerformance: Object.fromEntries(dailyPerformance),
    };
  }
}
