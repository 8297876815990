import {
  Challenge,
  IChallengeInitializer,
} from '../../challenge/challenge.abstract';
import { ITeamMember, TeamMember } from '../team-member/team-member';

export interface ITeamChallengeInitializer extends IChallengeInitializer {
  maxParticipantsPerTeam: number;
  membersWaitingForTeam: Array<ITeamMember>;
  durationInWeeks: number;
  locationLevel: number;
  currentWeek: number;
}

export class TeamChallenge extends Challenge {
  public maxParticipantsPerTeam: number = 0;
  public membersWaitingForTeam: Array<TeamMember> = [];
  public durationInWeeks: number = 0;
  public locationLevel: number = 0;
  public currentWeek: number = 0;

  constructor(initializer?: Partial<ITeamChallengeInitializer>) {
    super(initializer);
    if (initializer) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { features, ...initializerWithoutFeatures } = initializer;
      Object.assign(this, initializerWithoutFeatures);

      if (
        initializer.membersWaitingForTeam &&
        initializer.membersWaitingForTeam.length > 0
      ) {
        this.membersWaitingForTeam = initializer.membersWaitingForTeam.map(
          (iTeamMember) => new TeamMember(iTeamMember)
        );
      }
    }
  }

  public toObject(): ITeamChallengeInitializer {
    const {
      maxParticipantsPerTeam,
      membersWaitingForTeam,
      durationInWeeks,
      locationLevel,
      currentWeek,
    } = this;

    return {
      ...super.toObject(),
      maxParticipantsPerTeam,
      membersWaitingForTeam: membersWaitingForTeam.map((teamMember) =>
        teamMember.toObject()
      ),
      durationInWeeks,
      locationLevel,
      currentWeek,
    };
  }
}
