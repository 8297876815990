import { AbstractControl } from '@angular/forms';
import {
  COUNTRY_CODE_TOKEN,
  DEFAULT_IMAGE_PATH,
  FLAGS_PATH,
  FLAGS_PATH_4X3,
  MAT_CARD_CONTENT_TAG,
} from '../constants/constants';
import { DistanceChallenge } from '../models/distance-challenge.model';
import { ICitiesChallengeInitializer } from '../models/cities-challenge/cities-challenge/cities-challenge.model';
import { City } from '../models/cities-challenge/city/city.model';
import { CityMarkerPoint as OldCityMarkerPoint } from '../models/city-marker-point.model';
import { REGEX_ISO_DATE, REGEX_ISO_DATETIME } from '../constants/constants';
import { ICityMarkerPointInitializer } from '../models/cities-challenge/city-marker-point/city-marker-point.model';
import { ElementRef } from '@angular/core';
import { CitiesChallengePerformance } from '../models/cities-challenge/cities-challenge-performance/cities-challenge-performance.model';

export class Helpers {
  static unformatDate(date: string): Date {
    const dayArray = date.split('.');
    const returnDate = new Date();
    returnDate.setHours(0, 0, 0, 0);
    returnDate.setFullYear(Number(dayArray[2]));
    returnDate.setMonth(Number(dayArray[1]) - 1);
    returnDate.setDate(Number(dayArray[0]));
    return returnDate;
  }

  static formatDate(date: Date): string {
    return (
      date.getDate().toString().padStart(2, '0') +
      '.' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '.' +
      date.getFullYear()
    );
  }

  static formatDateTimestamp(timestamp: number): string {
    return this.formatDate(new Date(timestamp));
  }

  static formatDateFileTitle(date: Date): string {
    return (
      date.getFullYear() +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      date.getDate().toString().padStart(2, '0') +
      '_' +
      date.getHours() +
      date.getMinutes()
    );
  }

  static formatDateYYYYMMDD(date: Date): string {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0')
    );
  }

  static unformatDateYYYYMMDD(date: string): Date {
    const dateYYYYMMDD = date.split('T');
    const dayArray = dateYYYYMMDD[0].split('-');
    const returnDate = new Date();
    returnDate.setFullYear(
      Number(dayArray[0]),
      Number(dayArray[1]) - 1,
      Number(dayArray[2])
    );
    returnDate.setHours(0, 0, 0, 0);
    return returnDate;
  }

  static getTimeFromFormattedString(date: string): number {
    return this.unformatDate(date).getTime();
  }

  static formatDateByLanguage(date: Date, lang: string): string {
    return new Intl.DateTimeFormat(lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
  }

  static formatAndRoundNumberByLanguage(
    value: number,
    numberOfDecimals: number,
    lang: string
  ): string {
    const decimals: number = Math.pow(10, numberOfDecimals);
    return new Intl.NumberFormat(lang).format(
      Math.round(value * decimals) / decimals
    );
  }

  static hasDateFormat(testString: string): boolean {
    const stringSplit = testString.split('.');
    return stringSplit.length === 3;
  }

  /**
   * addDays will receive a date and an amount of days to add (it can also be negative) and will return a date with the added or taken
   * amount of days (according to the sign of the input)
   * @param date Date object.
   * @param daysToAdd number of days to add or substract (positive or negative integer respectively)
   * @returns Date with the added or substracted days amount.
   */
  static addDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  /**
   * getDateArray will return an array with all the individual dates comprised between two dates given as input
   * @param startDate Date object with the starting date of the array.
   * @param endDate Date object with the ending date of the array (included in the array).
   * @returns Date Array with all the individual dates comprised between two dates given as input.
   */
  static getDateArray(startDate: Date, endDate: Date): Array<Date> {
    const dateArray = new Array<Date>();

    const date = startDate;

    while (date <= endDate) {
      dateArray.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dateArray;
  }

  static generateDaysArray(
    startDateYYYYMMDD: string,
    endDateYYYYMMDD: string
  ): Array<string> {
    let startDate = this.unformatDateYYYYMMDD(startDateYYYYMMDD);
    const endDate = this.unformatDateYYYYMMDD(endDateYYYYMMDD);
    const daysArray = [];
    while (startDate <= endDate) {
      daysArray.push(this.formatDateYYYYMMDD(startDate));
      startDate = this.addDays(startDate, 1);
    }
    return daysArray;
  }

  static createISODateTime(date: Date = new Date()): string {
    const formattedDate = this.formatDateYYYYMMDD(date);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${formattedDate}T${hours}:${minutes}:${seconds}`;
  }

  static createISODate(date: Date = new Date()): string {
    return this.formatDateYYYYMMDD(date);
  }

  static createISOStartDateTime(date: Date = new Date()): string {
    return this.formatDateYYYYMMDD(date) + 'T00:00:00';
  }

  static createISOEndDateTime(date: Date = new Date()): string {
    return this.formatDateYYYYMMDD(date) + 'T23:59:59';
  }

  static getISOYearMonth(isoDateTime: string): string {
    if (
      isoDateTime.match(REGEX_ISO_DATETIME) ||
      isoDateTime.match(REGEX_ISO_DATE)
    ) {
      return isoDateTime.slice(0, 7);
    } else {
      return null;
    }
  }

  static toRad(x: number): number {
    return (x * Math.PI) / 180;
  }

  static getFlagPath(countryCode: string, get4x3?: boolean): string {
    return (get4x3 ? FLAGS_PATH_4X3 : FLAGS_PATH).replace(
      COUNTRY_CODE_TOKEN,
      countryCode.toLowerCase()
    );
  }

  static getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    if (control.errors) {
      const errorKey = Object.keys(control.errors)[0];
      return `${translationFormToken}.${controlName}.errors.${errorKey}`;
    }
    return '';
  }

  static isDistanceChallenge(value: object): value is DistanceChallenge {
    return 'dailyExpectedSteps' in value;
  }

  static transformDistanceChallengeInCitiesChallengeInitializer(
    distanceChallenge: DistanceChallenge
  ): ICitiesChallengeInitializer {
    const startDate = Helpers.formatDateYYYYMMDD(
      new Date(distanceChallenge.startDate)
    );
    const endDate = Helpers.formatDateYYYYMMDD(
      new Date(distanceChallenge.endDate)
    );

    const initializer: ICitiesChallengeInitializer = {
      // TRANSFORM DISTANCE CHALLENGE IN CITIES CHALLENGE INITIALIZER
      startDate,
      endDate,
      id: distanceChallenge.id,
      name: distanceChallenge.name,
      expectedDailySteps: distanceChallenge.dailyExpectedSteps,
      totalDistance: distanceChallenge.totalDistance,
      // ALWAYS CUSTOMIZABLE SINCE THEY ALWAYS HAVE ONLY 1 ROUTE, AND THEY CAN CHANGE IT (ACCORDING TO THE CONDITIONS OF CITIES CHALLENGES)
      features: ['CC_CUSTOMIZABLE'],
      totalPerformance: new CitiesChallengePerformance().toObject(),
      regions: [
        {
          startDate,
          endDate,
          duration: distanceChallenge.duration,
          route: {
            totalDistance: distanceChallenge.totalDistance,
            name: distanceChallenge.name,
            map: distanceChallenge.map,
            cityMarkerPoints: distanceChallenge.citiesMarkerPoints.map(
              (cityMarkerPoint: OldCityMarkerPoint, index: number) =>
                ({
                  city: new City(cityMarkerPoint).toObject(),
                  distanceToNextCity: distanceChallenge.distanceBetweenCities[
                    index
                  ]
                    ? distanceChallenge.distanceBetweenCities[index]
                    : null,
                  info: null,
                  cityInfoType: null,
                } as ICityMarkerPointInitializer)
            ),
          },
        },
      ],
    };

    return initializer;
  }

  static calculateCardContentHeight(
    contentElement: ElementRef,
    totalPadding: number = 0
  ): void {
    if (contentElement.nativeElement.tagName === MAT_CARD_CONTENT_TAG) {
      // Get the total height of the mat-card
      const cardHeight =
        contentElement.nativeElement.parentElement.clientHeight;

      // Get the height of the mat-card-header
      const headerHeight = contentElement.nativeElement.previousElementSibling
        ? contentElement.nativeElement.previousElementSibling.clientHeight
        : 0;

      // Calculate the height of the mat-card-content
      const contentHeight = cardHeight - headerHeight - totalPadding;

      // Set the calculated height to mat-card-content
      contentElement.nativeElement.style.height = `${contentHeight}px`;
    }
  }

  static setDefaultImagePath = (imageElement: HTMLImageElement): void => {
    imageElement.onerror = null;
    imageElement.src = DEFAULT_IMAGE_PATH;
  };
}
