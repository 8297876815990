import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { Company, ICompanyInitializer } from 'src/app/models/company.model';
import { GroupRunChallenge } from 'src/app/models/group-run/group-run-challenge/group-run-challenge';
import { GroupRunChallengeService } from 'src/app/services/group-run-challenge/group-run-challenge.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';

@Component({
  selector: 'app-group-run-challenge-dialog',
  templateUrl: './group-run-challenge-dialog.component.html',
  styleUrls: ['./group-run-challenge-dialog.component.scss'],
})
export class GroupRunChallengeDialogComponent implements OnInit {
  public challengeForm: UntypedFormGroup;
  public minDate: Date = new Date();

  public selectedCompany = new UntypedFormControl('');

  public companies: Array<Company> = [];
  public filteredCompanies: Observable<Array<Company>>;
  public challengeCompanies: Array<Company> = [];
  public dataSource = new MatTableDataSource(this.challengeCompanies);
  public companiesColumns: Array<string> = ['avatar', 'name', 'delete'];

  public showSpinner = false;

  public showCompanyName = (
    company?: ICompanyInitializer
  ): string | undefined => {
    if (company) {
      const companyObject = new Company(company);
      return companyObject.name;
    } else {
      return undefined;
    }
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<GroupRunChallengeDialogComponent>,
    private groupRunService: GroupRunChallengeService,
    private subscriptionService: SubscriptionService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: GroupRunChallenge
  ) {
    this.subscriptionService.subscribeToCompanies();

    this.subscriptionService.companies.subscribe((companies) => {
      if (companies) {
        this.companies = companies.sort((companyA, companyB) =>
          companyA.name > companyB.name ? 1 : -1
        );
        this.filteredCompanies = of(this.companies);

        if (
          this.data &&
          this.challengeCompanies.length !== this.data.participants.length
        ) {
          this.challengeCompanies = this.companies.filter((company) =>
            this.data.participants.includes(company.id)
          );
          this.initTable();
        }
      }
    });
  }

  get dateRange(): UntypedFormGroup {
    return this.challengeForm.controls.dateRange as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.challengeForm = this.groupRunService.getStandardGroupRunChallengeDataForm();

    if (this.data) {
      this.challengeForm.patchValue({
        id: this.data.id,
        name: this.data.name,
        projectPlanSent: this.data.projectPlanSent,
        features: Array.from(this.data.features),
        firstLevelTarget: this.data.firstLevelTarget,
        levelIncrementalFactor: this.data.levelIncrementalFactor,
        companies: this.data.participants,
      });

      this.dateRange.patchValue({
        start: new Date(this.data.startDate),
        end: new Date(this.data.endDate),
      });
    }

    this.selectedCompany.valueChanges.subscribe((selectedCompany) => {
      if (typeof selectedCompany !== 'string') {
        if (this.challengeCompanies.indexOf(selectedCompany) < 0) {
          this.challengeCompanies.push(selectedCompany);
          this.initTable();
        } else {
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              'GROUP_RUN_CHALLENGE_DIALOG.COMPANY_ALREADY_IN_CHALLENGE'
            )
          );
        }

        this.selectedCompany.reset('', { emitEvent: false });
        this.filteredCompanies = of(this.filterCompanies(''));
      } else {
        this.filteredCompanies = of(this.filterCompanies(selectedCompany));
      }
    });

    this.initTable();
  }

  public initTable(): void {
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.challengeCompanies);

    this.initSortAndPaginator();
  }

  private initSortAndPaginator(): void {
    // Initialize sort and paginator
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Redefine the filter method
    this.dataSource.filterPredicate = (
      data: Company,
      filter: string
    ): boolean => data.name.trim().toLowerCase().indexOf(filter) !== -1;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async confirm(): Promise<void> {
    if (this.challengeForm.valid) {
      this.showSpinner = true;
      const groupRunChallenge = new GroupRunChallenge({
        id: this.data ? this.data.id : null,
        name: this.challengeForm.value.name,
        startDate: Helpers.createISOStartDateTime(
          this.dateRange.controls.start.value
        ),
        endDate: Helpers.createISOStartDateTime(
          this.dateRange.controls.end.value
        ),
        participants: this.challengeCompanies.map((company) => company.id),
        firstLevelTarget: this.challengeForm.value.firstLevelTarget,
        levelIncrementalFactor: this.challengeForm.value.levelIncrementalFactor,
      });

      this.groupRunService
        .updateGroupRunChallenge(groupRunChallenge)
        .then(() => {
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              groupRunChallenge.id
                ? 'UPDATE_GROUP_RUN_RESPONSES.GROUP_RUN_UPDATED_CORRECTLY'
                : 'UPDATE_GROUP_RUN_RESPONSES.GROUP_RUN_CREATED_CORRECTLY',
              { name: groupRunChallenge.name }
            )
          );
          this.dialogRef.close();
        })
        .catch((error) => {
          console.log('error: ', error);
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              groupRunChallenge.id
                ? 'UPDATE_GROUP_RUN_RESPONSES.ERROR_UPDATING_GROUP_RUN'
                : 'UPDATE_GROUP_RUN_RESPONSES.ERROR_CREATING_GROUP_RUN',
              { name: groupRunChallenge.name }
            )
          );
        })
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }

  public removeCompany(companyId: string): void {
    this.challengeCompanies.splice(
      this.challengeCompanies.findIndex((company) => company.id === companyId),
      1
    );
    this.initTable();
  }

  public filterCompanies(val: string): Array<Company> {
    return this.companies.filter((company) =>
      company.name.toLowerCase().includes(val.toLowerCase())
    );
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
