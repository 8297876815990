import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MSECS_IN_A_DAY } from 'src/app/constants/constants';
import { AssetsConstants } from 'src/app/models/assets-constants';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge';

@Component({
  selector: 'app-team-challenge-starts-soon-card',
  templateUrl: './team-challenge-starts-soon-card.component.html',
  styleUrls: ['./team-challenge-starts-soon-card.component.scss'],
})
export class TeamChallengeStartsSoonCardComponent implements OnChanges {
  @Input() nextTeamChallenge: TeamChallengeV2;
  @Output() editButtonClicked = new EventEmitter<void>();

  public startsSoonImage: string = AssetsConstants.GuyWithPhone;
  public daysDifference: number = 15;
  public MAXIMAL_DAYS_DIFFERENCE_TO_SHOW = 15;
  public PROGRESS_WHEN_DIFFERENCE_ABOVE_MAXIMAL = 5;

  constructor() {}

  ngOnChanges(): void {
    this.daysDifference =
      Math.round(
        (new Date(this.nextTeamChallenge.startDate).getTime() - Date.now()) /
          MSECS_IN_A_DAY
      ) + 1;
  }

  public goToEdit(): void {
    this.editButtonClicked.emit();
  }
}
